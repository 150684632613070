import { makeFieldNotRequired, makeFieldRequired } from '@app/features/required_fields_helpers';
import { animateTransactionTypeContainer } from '@app/features/payment_type_select';
import { changeInputStateFor } from '@app/lib/utility/change_input_state';
import { exists } from '@app/lib/utility/exists';
import { isIeLessThan9 } from '@app/lib/utility/ie';
import { simulateKeyEvent } from '@app/lib/utility/simulate_keys';

function resetInputs($form) {
  $form.find('input').prop('value', '');
  $form.find('input:checkbox').prop('checked', '');
  $form.find('input:radio').prop('checked', '');
  $form.find('select option').filter('selected').removeAttr('selected');
}

function updateHiddenFormAttributes($hiddenForm) {
  resetInputs($hiddenForm);
  changeInputStateFor($hiddenForm, true);

  $hiddenForm.hide();
  $hiddenForm.css('opacity', 0);
}

function updateFormsAttributes(enabledForm, disabledForm) {
  changeInputStateFor($(enabledForm), false);
  updateHiddenFormAttributes($(disabledForm));

  $(enabledForm).show(0, function() {
    $(enabledForm).css('opacity', 1);
  });

  animateTransactionTypeContainer();
}

function checkForCardDependableFields(token) {
  var cardDependables = $('.token-' + token).data('card-dependables');
  var $container = $('.card-dependable-fields.with-tokenized-card');
  var $birthDate = $container.find('.birth-date');
  var $issuedCardId = $container.find('.issued-card-id');

  if (cardDependables && cardDependables.birth_date) {
    $birthDate.show();
    changeInputStateFor($birthDate, false);
  } else {
    $birthDate.hide();
    changeInputStateFor($birthDate, true);
  }

  if (cardDependables && cardDependables.issued_card_id) {
    makeFieldRequired('issued-card-id');
    changeInputStateFor($issuedCardId, false);
  } else {
    makeFieldNotRequired('issued-card-id');
    changeInputStateFor($issuedCardId, true);
  }

  animateTransactionTypeContainer();
}

function toggleForm(selectedToken) {
  if (selectedToken === '') {
    updateFormsAttributes('.credit-card-form', '.tokenized-card-form');

    if(!isIeLessThan9()) {
      var $cardNumberField = $('#wpf_form_card_number');

      simulateKeyEvent($cardNumberField.get(0), 'keyup');
      simulateKeyEvent($cardNumberField.get(0), 'keypress');
    }
  } else {
    updateFormsAttributes('.tokenized-card-form', '.credit-card-form');
    resetInputs($('.tokenized-card-form'));
    checkForCardDependableFields(selectedToken);
  }
}

function handleMarkCardDefaultResponse(tokenId) {
  return function(response) {
    const cardToken = $('.token-' + tokenId);

    if (response.error) {
      cardToken.find('.error.token_mapping').removeClass('hidden');
    } else {
      $('div[class^="token-"]').each(function(idx, cardTokenContainer) {
        $(cardTokenContainer).find('.default-card').addClass('hidden');
        $(cardTokenContainer).find('.mark-default').removeClass('hidden');
      });
      cardToken.find('.mark-default').addClass('hidden');
      cardToken.find('.default-card').removeClass('hidden');
      cardToken.find('input[type="radio"]').prop('checked', true);
    }
  };
}

function handleTokenInvalidateResponse(tokenId) {
  return function(response) {
    const cardToken = $('.token-' + tokenId);
    const tokenRemoveWarningDiv = $('.token-' + tokenId + '-warning');

    if (response.error) {
      tokenRemoveWarningDiv.hide();
      cardToken.show();
      cardToken.find('.error.token_mapping').removeClass('hidden');
    } else {
      tokenRemoveWarningDiv.hide(function() {
        cardToken.hide(function() {
          $(this).remove();
        });

        const tokens = $('input[name="token_id"]');

        if (tokens.length === 1) {
          $('.add-new-card').click();
        }
      });
    }
  };
}

function markCardDefaultRequest(wpfId, tokenId) {
  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');

  $.ajax({
    method: 'PUT',
    url: '/card_operations/' + tokenId,
    headers: {
      'X-CSRF-Token': csrfMetaTag.content
    },
    data: { wpf_unique_id: wpfId, default_card: true }
  }).done(handleMarkCardDefaultResponse(tokenId));
}

function invalidateTokenRequest(wpfId, tokenId) {
  const csrfMetaTag = document.querySelector('meta[name="csrf-token"]');

  $.ajax({
    method: 'POST',
    url: '/wpf/invalidate_card_token',
    headers: {
      'X-CSRF-Token': csrfMetaTag.content
    },
    data: { wpf_unique_id: wpfId, token_id: tokenId }
  }).done(handleTokenInvalidateResponse(tokenId));
}

function listenForTokenInvalidateCancel($tokenContainerDiv, $tokenRemoveWarningDiv) {
  $('.tokens-container button.cancel').on('click', function() {
    $tokenContainerDiv.show();
    $tokenRemoveWarningDiv.hide();
  });
}

function listenForTokenInvalidateConfirm($tokenRemoveWarningDiv, tokenId) {
  $tokenRemoveWarningDiv.find('button.confirm').on('click', function() {
    const wpfId = window.location.pathname.split('/').pop();

    if (!wpfId || !tokenId) {
      return;
    }

    invalidateTokenRequest(wpfId, tokenId);
  });
}

export function listenForTokenInvalidate() {
  $('.invalidate-token').on('click', function(event) {
    const tokenId = $(this).parent().prev().val();
    const $tokenContainerDiv = $(this).parent().parent();
    const $tokenRemoveWarningDiv = $('.token-' + tokenId + '-warning');

    event.preventDefault();
    $tokenContainerDiv.hide();
    $tokenRemoveWarningDiv.show();

    listenForTokenInvalidateConfirm($tokenRemoveWarningDiv, tokenId);
    listenForTokenInvalidateCancel($tokenContainerDiv, $tokenRemoveWarningDiv);
  });
}

export function listenForTokenMarkDefault() {
  $('.mark-default').on('click', function(event) {
    const tokenId = $(this).parent().prev().val();
    const wpfId = window.location.pathname.split('/').pop();

    event.preventDefault();

    markCardDefaultRequest(wpfId, tokenId);
  });
}

export function listenForTokenMappingSelection() {
  const $tokensMappingContainer = $('.tokens-container');

  if (exists($tokensMappingContainer)) {
    $tokensMappingContainer.on('change', 'input[type=radio]', function() {
      toggleForm($(this).attr('value'));

      $tokensMappingContainer.parent().find('span.error').addClass('hidden');
    });
  }
}

export function handleTokenization() {
  const $tokensMappingContainer = $('.tokens-container');

  if (exists($tokensMappingContainer)) {
    toggleForm($('input[name=token_id]:checked').val());
  }
}
