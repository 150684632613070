/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import PropTypes from 'prop-types'
import React     from 'react'

const INTERVAL = 1000

class CountdownRedirect extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      countdown: props.seconds
    }
  }

  /*
   * Component life cycle
   */

  componentDidMount() {
    this.startCounting()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  /*
   * Render component
   */

  render() {
    return (
      <div>
        {I18n.t('javascript.confirmation.countdown', { count: this.state.countdown })}
      </div>
    )
  }

  /*
   * Private methods
   */

  startCounting() {
    this.interval = setInterval(() => {
      this.countdown()
    }, INTERVAL)
  }

  countdown() {
    this.state.countdown
      ? this.setState({ countdown: this.state.countdown - 1 })
      : this.props.redirect()
  }
}

CountdownRedirect.propTypes = {
  redirect: PropTypes.func.isRequired,
  seconds:  PropTypes.number.isRequired
}

export default CountdownRedirect
