import { makeFieldNotRequired, makeFieldRequired } from '@app/features/required_fields_helpers';

function toggleConsumerRefRequired(gateway) {
  if (gateway === 'Girogate') {
    makeFieldRequired('wpf_form_consumer_reference');
  } else {
    makeFieldNotRequired('wpf_form_consumer_reference');
  }
}

export function handleConsumerReference(gateway) {
  toggleConsumerRefRequired(gateway);
}
