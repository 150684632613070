/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import PropTypes from 'prop-types'
import React  from 'react'

class TermsOfUseModal extends React.Component {

  render() {
    const { terms } = this.props

    return (
      <>
        <h5>{I18n.t('javascript.modals.terms_of_use.title')}</h5>
        <div>
          {terms.template?.map(templateFragment => {
            const { termsAndConditions, privacyPolicy } = terms

            if (templateFragment === 'terms_and_conditions') {
              return (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={termsAndConditions.url} key={termsAndConditions.url} target='_blank'>
                  {termsAndConditions.text}
                </a>)
            }

            if (templateFragment === 'privacy_policy') {
              return (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a href={privacyPolicy.url} key={privacyPolicy.url} target='_blank'>
                  {privacyPolicy.text}
                </a>)
            }

            return <span key={templateFragment}>{templateFragment}</span>
          })}
          {terms.billing && terms.billing}
        </div>
      </>
    )
  }
}

TermsOfUseModal.propTypes = {
  terms: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ])
}

export const termsOfUseModalProps = () => ({
  submitLabel: I18n.t('javascript.actions.confirm'),
  cancelLabel: I18n.t('javascript.actions.cancel'),
  className:   'terms-of-use'
})

export default TermsOfUseModal
