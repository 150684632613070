function listenForClick() {
  const $payLaterBtn = $('.submit-later');

  $payLaterBtn.on('click', function(event) {
    event.stopImmediatePropagation();
    $payLaterBtn.addClass('is-disabled loading');
  });
}

export default { listenForClick };
