export function updateEntercashCustomAttributes() {
  if ($('#wpf_form_transaction_type_entercash').not(':checked').length) {
    return 0;
  }

  var billing_country = $('#wpf_form_billing_address_country').val();
  var element = $('#entercash_custom_attributes');
  var countries = ['AT', 'DE'];

  if (countries.indexOf(billing_country) !== -1) {
    element.show();
  } else {
    element.hide();
  }

  $('#transaction-types-form-container')
    .height($('.transaction-type-form:not(.hidden)').innerHeight() || 0);
}
