import { exists } from '@app/lib/utility/exists';

function submitThreedsMethod() {
  $('#threeds-method-form').trigger('submit');
}

// ThreedsMethod and ThreedsMethodContinue API calls should be done in parallel
// Performing AJAX call to method continue URL which will execute method continue
// And process the transaction after that
// Then we change the location of the current window in order to properly redirect the consumer
//
function submitThreedsMethodContinue() {
  var threedsMethodContinueUrl = $('div#threeds-method').data('threeds-method-continue-url');
  var threedsMethodFinalizeUrl = $('div#threeds-method').data('threeds-finalize-url');

  $.ajax({
    method: 'GET',
    url: threedsMethodContinueUrl
  })
    .done(function() {
      window.location.href = threedsMethodFinalizeUrl;
    });
}

function performThreedsMethod() {
  if (exists($('#threeds-method-form'))) {
    submitThreedsMethod();
    submitThreedsMethodContinue();
  }
}

export default { performThreedsMethod };
