require('jquery.card');

const DEFAULT_WIDTH = 450;

function messages() {
  return {
    validDate: I18n.t('javascript.turning_card.valid') + '\n' +
               I18n.t('javascript.turning_card.thru'),
    monthYear: I18n.t('javascript.turning_card.month') + '/' +
               I18n.t('javascript.turning_card.year'),
    legalText: ''
  };
}

function formSelectorsFor(trx_type_class) {
  var selector = '.transaction-type-form';
  var trx_type_selector = selector + trx_type_class;

  return {
    numberInput: trx_type_selector + ' input#wpf_form_card_number',
    expiryInput: trx_type_selector + ' select[name="wpf_form[expiration_date(2i)]"], ' +
                 trx_type_selector + ' select[name="wpf_form[expiration_date(1i)]"]',
    cvcInput: trx_type_selector + ' input#wpf_form_requires_cvv',
    nameInput: trx_type_selector + ' input#wpf_form_card_holder'
  };
}

function defaultOptions() {
  var formSelectors = formSelectorsFor('.credit_card');
  var $container = $('.card-wrapper.credit-card');

  return {
    width: DEFAULT_WIDTH,
    container: $container,
    formSelectors: formSelectors,
    messages: messages(),
    values: {
      name: I18n.t('javascript.turning_card.full_name')
    }
  };
}

function eZeeWalletPayoutOptions() {
  var options = defaultOptions();

  options.container = $('.card-wrapper.ezeewallet_payout');
  options.formSelectors = formSelectorsFor('.ezeewallet_payout');

  return options;
}

function accountVerificationOptions() {
  var options = defaultOptions();

  options.container = $('.card-wrapper.account_verification');
  options.formSelectors = formSelectorsFor('.account_verification');

  return options;
}

export function loadInteractiveCard() {
  var default_options              = defaultOptions();
  var payoutOptions                = eZeeWalletPayoutOptions();
  var account_verification_options = accountVerificationOptions();

  $('.transaction-type-form.credit_card').card(default_options);
  $('.transaction-type-form.ezeewallet_payout').card(payoutOptions);
  $('.transaction-type-form.account_verification').card(account_verification_options);
}
