function hideOnlineBankingField(fieldName) {
  $('.' + fieldName).addClass('hidden');
  $('.' + fieldName).removeClass('required');
  $('#' + fieldName).removeAttr('required');
}

function showOnlineBankingField(fieldName) {
  $('.' + fieldName).removeClass('hidden');
  $('.' + fieldName).addClass('required');
  $('#' + fieldName).attr('required');
}

function toggleOnlineBankingFields(paymentType) {
  if (paymentType === 'qr_payment' || paymentType === 'quick_payment') {
    hideOnlineBankingField('wpf_form_bank_code');
  } else {
    showOnlineBankingField('wpf_form_bank_code');
  }
}

export function handlePaymentTypes() {
  var $wpfFormPaymentTypeSelect = $('#wpf_form_payment_type');

  toggleOnlineBankingFields($wpfFormPaymentTypeSelect.val());

  $($wpfFormPaymentTypeSelect).on('change', function() {
    toggleOnlineBankingFields($(this).val());
  });
}

export function handleOnlineBankingBankCode(gateway) {
  if (gateway === 'PandaPay') {
    hideOnlineBankingField('wpf_form_bank_code');
    $('#wpf_form_payment_type').off('change');
  } else {
    toggleOnlineBankingFields($('#wpf_form_payment_type').val());
  }
}
