/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */
import 'cross-fetch/polyfill'

import ApplePayManager from '@app/lib/modules/apple-pay-manager'
import PropTypes       from 'prop-types'
import React           from 'react'

class ApplePayButton extends React.Component {

  constructor(props) {
    super(props)

    this.applePayManager = new ApplePayManager(this.props.supportedNetworks)
    this.typeCheckbox = React.createRef()
    this.applePayClicked = this.applePayClicked.bind(this)
    this.state = { token: '' }
  }

  /*
   * Render component
   */

  render() {
    if (!ApplePayManager.isApplePaySupported()) {
      return null
    }

    return (
      <div className='apple-pay-transaction-type'>
        <div className='apple-pay-button apple-pay-button-black'
          onClick={() => this.props.onClick(this.applePayClicked)}>
        </div>
        { this.state.token &&
          <input type='hidden' name='wpf_form[payment_token]' value={ this.state.token } /> }
        <input type='radio'
          name='wpf_form[transaction_type]'
          value='apple_pay'
          style={ { display: 'none' } }
          ref={this.typeCheckbox}/>
      </div>
    )
  }

  /*
   * Private methods
   */

  applePayClicked() {
    this.typeCheckbox.current.checked = true

    const session = this.applePayManager.applePaySessionRequest(this.props)

    session.onvalidatemerchant = async event => {

      /* Delegates to our server to request a merchant session.
       * Response expected: MerchantSession Object
       */
      const merchantSession = await ApplePayManager.merchantSessionRequest(event.validationURL)

      session.completeMerchantValidation(merchantSession)
    }

    session.onpaymentauthorized = event => {
      const token = event.payment.token

      /* Process the payment when authorized.
       * The token is in event.payment
       *
       * NB: The response delay to onpaymentauthorized event is limited by a 30 seconds timeout.
       * After that an error message appears on the payment sheet.
       */

      this.setState({ ...this.state, token: JSON.stringify(token) }, () =>
        document.getElementById('new_wpf_form').submit()
      )

      session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
    }

    /* Shows the modal */
    session.begin()
  }

}

ApplePayButton.propTypes = {
  countryCode:          PropTypes.string.isRequired,
  currencyCode:         PropTypes.string.isRequired,
  supportedNetworks:    PropTypes.array.isRequired,
  merchantCapabilities: PropTypes.array.isRequired,
  merchantName:         PropTypes.string.isRequired,
  amount:               PropTypes.string.isRequired,
  onClick:              PropTypes.func.isRequired
}

ApplePayButton.defaultProps = {}

export default ApplePayButton
