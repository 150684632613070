import { animateTransactionTypeContainer } from '@app/features/payment_type_select';
import { exists } from '@app/lib/utility/exists';
import { handleDocumentIdType } from '@app/features/handle_document_id_type';
import { handlePersonalAttributes } from '@app/features/handle_personal_attributes';
import { toggleStateFor } from '@app/features/state_change';
import { updateEntercashCustomAttributes } from '@app/features/entercash_custom_attributes';

export function changeLabelPerCountry(labelFor, newValue, country, initialLabel) {
  var billingCountry = $('#wpf_form_billing_address_country').val();
  var attributeLabel = $(`label[for=${labelFor}]`);
  var isBillingCountryAvailable = billingCountry && billingCountry.length > 0;

  if (initialLabel && exists(attributeLabel) && isBillingCountryAvailable) {
    var attributeLabelChanged = null;

    if (billingCountry === country) {
      attributeLabelChanged = attributeLabel.html()
        .replace(initialLabel, newValue);
    } else {
      attributeLabelChanged = attributeLabel.html()
        .replace(newValue, initialLabel);
    }

    attributeLabel.html(attributeLabelChanged);
  }
}

export function listenForCountryChange() {
  const documentIdLabel = $('label[for="wpf_form_document_id"]');
  const nationalIdLabel = $('label[for="wpf_form_national_id"]');

  let initIdLabel = '';

  if(documentIdLabel.length !== 0) {
    initIdLabel = documentIdLabel;
  } else {
    initIdLabel = nationalIdLabel;
  }
  var initIdLabelValue = initIdLabel ? initIdLabel.html() : null;

  handlePersonalAttributes(initIdLabelValue);

  $('body').on('change', '#wpf_form_billing_address_country', function() {
    updateEntercashCustomAttributes();
    toggleStateFor($('#wpf_form_billing_address_country'));
    handlePersonalAttributes(initIdLabelValue);
    animateTransactionTypeContainer();
    handleDocumentIdType($('#document_id_section').data()?.transaction_type);
  });
}
