/* eslint semi: ["error", "never"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Button                         from '@app/components/presentational/base/button'
import { ReactComponent as CopyIcon } from '../../../images/copy-icon.svg'
import PropTypes                      from 'prop-types'
import React                          from 'react'

class CopyContainer extends React.Component {

  constructor(props) {
    super(props)

    this.targetTextInput = React.createRef()
  }

  /**
   * Render component
   */

  render() {
    return(
      <div>
        <Button
          className='copy-btn'
          onClick={ () => this.copy() }>
          <CopyIcon />
          {` ${I18n.t('javascript.actions.copy')}`}
          <input
            type='text'
            style={{ position: 'fixed', left: '-9999px', top: '-9999px' }}
            defaultValue={ this.props.target }
            ref={this.targetTextInput} />
        </Button>
        { this.props.showTarget &&
          <span className={this.props.targetClassName}>{this.props.target}</span> }
      </div>
    )
  }

  /**
   * Private methods
   */

  copy() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.props.target)
    } else {
      this.copyToClipboard()
    }
  }

  copyToClipboard() {
    this.targetTextInput.current.select()

    document.execCommand('copy')
  }
}

CopyContainer.propTypes = {
  target:          PropTypes.string.isRequired,
  targetClassName: PropTypes.string,
  showTarget:      PropTypes.bool
}

CopyContainer.defaultProps = {
  showTarget: true
}

export default CopyContainer
