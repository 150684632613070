/* eslint no-underscore-dangle: ['error', { 'allow': ['_ukVisaBins', '_ukDebitMastercardBins'] }] */

class FinancialServicesBins {

  constructor() {
    this._ukVisaBins = [];
    this._ukDebitMastercardBins = [];
  }

  get ukVisaBins() {
    return this._ukVisaBins;
  }

  get ukDebitMastercardBins() {
    return this._ukDebitMastercardBins;
  }

  set ukVisaBins(bins) {
    if (bins && bins.length !== 0) {
      this._ukVisaBins = bins;
    }
  }

  set ukDebitMastercardBins(bins) {
    if (bins && bins.length !== 0) {
      this._ukDebitMastercardBins = bins;
    }
  }

  includes(bin) {
    return this.ukVisaBins.includes(Number(bin)) ||
      this.ukDebitMastercardBins.includes(Number(bin));
  }

  isIncomplete() {
    return this.ukVisaBins.length === 0 ||
      this.ukDebitMastercardBins.length === 0;
  }
}

const financialServicesBins = new FinancialServicesBins();

export default financialServicesBins;
