import { makeFieldNotRequired, makeFieldRequired } from '@app/features/required_fields_helpers';

export function handleNeosurfFieldsVisibility(gateway) {
  if (gateway === 'Neosurf') {
    $('.voucher_number').addClass('hidden');
    makeFieldNotRequired('wpf_form_voucher_number');
  } else {
    $('.voucher_number').removeClass('hidden');
    makeFieldRequired('wpf_form_voucher_number');
  }
}
