import { changeLabelPerCountry } from '@app/features/country_dependent_fields';
import { makeFieldRequired } from '@app/features/required_fields_helpers';

const COUNTRIES_WITH_DOCUMENT_ID = ['AR', 'TR', 'IN', 'UY', 'BR', 'CO', 'PY', 'MX', 'PE', 'CL'];
const brazilianCountryCode = 'BR';

function requiresDocumentId() {
  return COUNTRIES_WITH_DOCUMENT_ID.includes($('#wpf_form_billing_address_country').val());
}

function togglePersonalAttributes() {
  var $documentId = $('.document_id');
  var $cpfCnpj = $('.cpf_cnpj');
  const documentIdAttributes = {
    field: $('#wpf_form_document_id'),
    label: $('label[for="wpf_form_document_id"]')
  };

  if (requiresDocumentId()) {
    $documentId.removeClass('hidden');
    if ($('#wpf_form_billing_address_country').val() === brazilianCountryCode) {
      $cpfCnpj.removeClass('hidden');
    } else {
      $cpfCnpj.addClass('hidden');
      documentIdAttributes.field.attr('placeholder', '');
      documentIdAttributes.label.html(I18n.t('javascript.wpf_form.document_id'));
    }
  } else {
    $documentId.addClass('hidden');
    $cpfCnpj.addClass('hidden');
  }
}

export function handlePersonalAttrLabel(initialLabel) {
  if ($('#wpf_form_billing_address_country').val() === brazilianCountryCode &&
      $('label[for="wpf_form_national_id"]').length === 0) {
    return;
  }

  const requiredAbbr = '<abbr title="required">*</abbr>';
  const documentLabel = $('label[for="wpf_form_document_id"]');

  // eslint-disable-next-line no-param-reassign
  initialLabel = initialLabel.replace(requiredAbbr, '');

  var cpfLabel = I18n.t('javascript.wpf_form.cpf_cnpj');
  var withCpfLabel = initialLabel.includes(cpfLabel);
  var valueToReplace = withCpfLabel ? initialLabel : cpfLabel;
  var valueToBeReplaced = withCpfLabel ? cpfLabel : initialLabel;
  var labelToBeChanged = documentLabel.length ? 'wpf_form_document_id' : 'wpf_form_national_id';


  changeLabelPerCountry(labelToBeChanged, valueToReplace, 'BR', valueToBeReplaced);

  if (requiresDocumentId()) {
    makeFieldRequired(labelToBeChanged);
  }
}

export function handlePersonalAttributes(initialLabel) {
  togglePersonalAttributes();
  if (initialLabel) {
    handlePersonalAttrLabel(initialLabel);
  }
}

