/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Button from '@app/components/presentational/base/button'
import CopyContainer from '@app/components/presentational/copy-container'
import I18n from 'i18n-js'
import PropTypes from 'prop-types'
import React from 'react'

class QrCodePage extends React.Component {

  redirect = () => {
    window.location.replace(this.props.returnUrl)
  }

  render() {
    const { qrCodeImage, description, withCopyButton } = this.props

    return (
      <div className='qr-code-page'>
        {withCopyButton &&
          <p className='qr-code-top-message'>{I18n.t('javascript.qr_page.scan_qr_code')}</p>
        }
        <div className='img-wrapper qr-code-container'>
          <img id='qr-code' src={qrCodeImage} alt='QR Code' />
        </div>
        {withCopyButton
          ? <CopyContainer target={description} targetClassName='qr-code-description' />
          : <p className='qr-code-description'>{description}</p>
        }
        <Button className='redirect-button' onClick={this.redirect}>
          {I18n.t('javascript.confirmation.to_website')}
        </Button>
      </div>
    )
  }
}

QrCodePage.propTypes = {
  qrCodeImage:    PropTypes.string.isRequired,
  description:    PropTypes.string.isRequired,
  withCopyButton: PropTypes.bool.isRequired,
  returnUrl:      PropTypes.string.isRequired
}

export default QrCodePage
