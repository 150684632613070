import { makeFieldNotRequired, makeFieldRequired } from '@app/features/required_fields_helpers';
// eslint-disable-next-line max-len
import { populateCardNumberFields, populateExpirationDateFields } from '@app/features/populate_card';
import { animateTransactionTypeContainer } from '@app/features/payment_type_select';
import { toggleShippingAddressFields } from '@app/features/shipping_support';
import { toggleState } from '@app/features/state_change';

function updateMerchantCountry(country) {
  $('.merchant-information .merchant-country').text(country);
}

function showBillingAddressFields() {
  $('.billing-address').removeClass('hidden');
  $('.field_with_errors').css('height', 'auto');
}

function showAcceptedCardBrands(accepted_cards) {
  $('.accepted-cards li').hide();

  $.each(accepted_cards, function(index, card_brand) {
    $('.accepted-cards .card-logo[data-brand="' + card_brand + '"]')
      .parent('li').show();
  });
}

export function showRequiredFields(data) {
  var card = data.populate_fields.card;

  $.each(data.required_fields, function(key, value) {
    var hiddenWpfField = data.hidden_wpf_fields.includes(String(key));

    if(value === true) {
      makeFieldRequired(key, hiddenWpfField);
    }

    if(value === false) {
      makeFieldNotRequired(key, hiddenWpfField);
    }
  });

  if (data.with_birth_date) {
    $('.birth-date')
      .removeClass('hidden')
      .addClass('with-birth-date');
  } else {
    $('.birth-date')
      .addClass('hidden')
      .removeClass('with-birth-date');
  }

  toggleShippingAddressFields();

  if(typeof card !== 'undefined') {
    populateCardNumberFields(card.bin, card.tail);
    populateExpirationDateFields(card.month, card.year);
  }

  if(data.populate_fields.country) {
    updateMerchantCountry(data.populate_fields.country);
  }

  toggleState();
  showAcceptedCardBrands(data.card_brands);
  showBillingAddressFields();
  animateTransactionTypeContainer();
}
