/* eslint semi: ["error", "never"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import PropTypes from 'prop-types'
import React     from 'react'

class Button extends React.Component {

  render() {
    return (
      <button
        className={this.props.className}
        onClick={this.props.onClick}>
        {this.props.children}
      </button>
    )
  }
}

Button.propTypes = {
  className: PropTypes.string,
  onClick:   PropTypes.func,
  children:  PropTypes.node.isRequired
}

export default Button
