/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import CopyContainer from '@app/components/presentational/copy-container'
import PropTypes     from 'prop-types'
import React         from 'react'

const SpeiRow = props => <div className='item'>
  <div className='body-text'>
    {props.label}
  </div>
  <div className='body-leading-text'>
    <div className='text-break'>
      { props.value }
    </div>
    { props.valueIcon && <CopyContainer
      target={props.value}
      showTarget = {false}
    /> }
  </div>
</div>

SpeiRow.propTypes = {
  label:     PropTypes.string,
  value:     PropTypes.string,
  valueIcon: PropTypes.bool
}

export default SpeiRow
