/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Button            from '@app/components/presentational/base/button'
import CountdownRedirect from '@app/components/presentational/countdown-redirect'
import PropTypes         from 'prop-types'
import React             from 'react'

class RedirectButton extends React.Component {

  /*
   * Render component
   */

  render() {

    if (!this.props.redirectUrl) {
      return null
    }

    return (
      <>
        <Button
          className={this.props.buttonClass}
          onClick={() => this.redirect()}>
          {I18n.t('javascript.confirmation.to_website')}
        </Button>
        { this.props.withDelay &&
          <CountdownRedirect
            seconds={this.props.delaySeconds}
            redirect={() => this.redirect()} /> }
      </>
    )
  }

  /*
   * Private methods
   */

  redirect() {
    window.location.replace(this.props.redirectUrl)
  }
}

RedirectButton.propTypes = {
  buttonClass:  PropTypes.string,
  delaySeconds: PropTypes.number,
  redirectUrl:  PropTypes.string,
  withDelay:    PropTypes.bool
}

RedirectButton.defaultProps = {
  withDelay: false
}

export default RedirectButton
