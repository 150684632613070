import { isIeLessThan9 } from '@app/lib/utility/ie';

// IE <= 9

function handleIeRadioClicks(container) {
  $('.' + container + ' label').click(function() {

    $('.' + container + ' label').removeClass('selected');
    $(this).addClass('selected');

    if ($(this).attr('for') !== '') {
      $('#' + $(this).attr('for')).click();
    }
  });

  $('.' + container + ' input[type="radio"]').filter(':checked').each(function() {
    $(this).siblings('label').addClass('selected');
  });
}

function handleIeCheckboxClicks(container) {
  var label = $('.' + container + ' label');
  var checkbox = $('#' + label.attr('for'));

  label.click(function() {
    if ($(this).attr('for') !== '') {
      checkbox = $('#' + $(this).attr('for'));

      if(checkbox.is(':checked')) {
        label.removeClass('selected');
      }else{
        label.addClass('selected');
      }

      checkbox.click();
    }
  });

  $('.' + container + ' input[type="checkbox"]').filter(':checked').each(function() {
    $(this).siblings('label').addClass('selected');
  });
}

export function handleIeSpecificScripts() {
  if(isIeLessThan9()) {
    handleIeRadioClicks('transaction-types');
    handleIeRadioClicks('payment-types');
    handleIeCheckboxClicks('tac');
  }
}
