import { REQUIRED_FIELDS } from '@app/features/payment_type_select';

export function toggleStateFor($countrySelect) {
  const country = $countrySelect.val();
  const stateCodeRequired = $('.billing-address-states').attr('data-states-code-required');
  const stateWrapperSelector = `.${$countrySelect.attr('id')?.replace('country', 'state')}`;

  /* We have more than one state wrapper - for US, CA, CN */
  const $stateWrappers = $(stateWrapperSelector);

  $stateWrappers.find('select').removeAttr('required');
  $stateWrappers.hide();

  if (country && stateCodeRequired.includes(country)) {
    const $stateSelectForCountry = $stateWrappers.find('select.' + country);

    $stateSelectForCountry.parent().show();

    if (REQUIRED_FIELDS.wpf_form_billing_address_state) {
      $stateSelectForCountry.attr('required', true);
    }
  } else {
    $stateWrappers.find('select').val('');
  }
}

export function toggleState() {
  toggleStateFor($('#wpf_form_billing_address_country'));
  toggleStateFor($('#wpf_form_shipping_address_country'));
}
