import { applyChosenToSelectBoxes } from '@app/features/apply_chosen';
import { toggleState } from '@app/features/state_change';
import { updateEntercashCustomAttributes } from '@app/features/entercash_custom_attributes';

function addCountriesOptionsToDropdown(selector, options, selected) {
  $.each(options, function(key, value) {
    var option = $('<option/>').attr('value', value).text(key);

    if(value === selected) {
      option.attr('selected', 'selected');
    }

    $(selector).append(option);
  });
}

function addPleaseSelectOption(selector) {
  var pleaseSelectOption = '<option value="">' +
                           I18n.t('javascript.options.please_select') +
                           '</option>';

  $(selector).append(pleaseSelectOption);
}

function selectInput(options) {
  // Example: options.id = wpf_form_billing_address_country
  // Example: options.name = wpf_form[billing_address_country]

  return '<select class="select optional for_chosen" data-placeholder="Select country" id="' +
         options.id + '" name="' + options.name + '" tabindex="-1">';
}

export function addSupportedCountries(transaction_type, payment_type) {
  var wpf_unique_id = window.location.pathname.split('/').pop();
  var billing_country = 'wpf_form_billing_address_country';
  var shipping_country = 'wpf_form_shipping_address_country';

  $.ajax({
    url: '/payment/supported_countries/' + transaction_type,
    data: {
      payment_type: payment_type,
      wpf_unique_id: wpf_unique_id
    },
    beforeSend: function() {
      $('#' + billing_country).prop('disabled', true).trigger('chosen:updated');
      $('#' + shipping_country).prop('disabled', true).trigger('chosen:updated');
    }
  }).done(function(data) {

    var selectedBillingCountry = $('#' + billing_country + ' option:selected').val();
    var selectedShippingCountry = $('#' + shipping_country + ' option:selected').val();

    $('#' + billing_country).remove();
    $('#' + billing_country + '_chosen').remove();
    $('#' + shipping_country).remove();
    $('#' + shipping_country + '_chosen').remove();

    $('.' + billing_country)
      .append(selectInput({ id: billing_country, name: 'wpf_form[billing_address_country]' }));

    $('.' + shipping_country)
      .append(selectInput({ id: shipping_country, name: 'wpf_form[shipping_address_country]' }));

    addPleaseSelectOption('#' + billing_country);
    addPleaseSelectOption('#' + shipping_country);
    addCountriesOptionsToDropdown('#' + billing_country, data, selectedBillingCountry);
    addCountriesOptionsToDropdown('#' + shipping_country, data, selectedShippingCountry);

    applyChosenToSelectBoxes();
    toggleState();
    updateEntercashCustomAttributes();
  });
}
