export function applyChosenToSelectBoxes() {
  var options = {
    width: '100%',
    search_contains: true
  };

  $('.for_chosen').chosen(options);

  var mobileAgents = /iPhone|iPod|IEMobile|Windows Phone|BlackBerry|BB10|Android.*Mobile/i;
  var isMobile = mobileAgents.test(navigator.userAgent);

  if (isMobile) {
    $('.for_chosen').addClass('mobile');
  }
}
