import { makeFieldNotRequired, makeFieldRequired } from '@app/features/required_fields_helpers';
import { REQUIRED_FIELDS } from '@app/features/payment_type_select';
import { toggleStateFor } from '@app/features/state_change';

function isParamPresent(param) {
  return Object.prototype.hasOwnProperty.call(REQUIRED_FIELDS, param);
}

function isRequiredParam(param) {
  return REQUIRED_FIELDS[param] === true;
}

function forEachShippingAddressField(callback) {
  var $shipping_address_fields = $('[id^="wpf_form_shipping_address_"]')
    .not('[id$="_checkbox"], [id$="_chosen"]');

  $shipping_address_fields.each(function(index, field) {
    callback($(field));
  });
}

function mirrorVisibleBillingAddressFields() {

  function formHasVisibleBillingAddressField(field_name) {
    var $billing_address_field = $('#wpf_form_billing_address_' + field_name);

    return $billing_address_field.parent().css('display') !== 'none';
  }

  forEachShippingAddressField(function($shipping_address_field) {
    var field_name = $shipping_address_field.attr('id').replace('wpf_form_shipping_address_', '');

    if (formHasVisibleBillingAddressField(field_name)) {
      $shipping_address_field.parent().show();
    }
  });
}

function makeShippingAddressFieldsRequired(required) {

  forEachShippingAddressField(function($field) {
    var api_param = $field.attr('name');

    if(isRequiredParam(api_param) && isParamPresent(api_param) && required) {
      makeFieldRequired($field.attr('id'));
    } else {
      makeFieldNotRequired($field.attr('id'));
    }
  });
}

function clearShippingAaddressFields() {
  $('.shipping-address-fields').find('input, select').val('');
  $('#wpf_form_shipping_address_country').trigger('chosen:updated');

  toggleStateFor($('#wpf_form_shipping_address_country'));
}

export function toggleShippingAddressFields() {
  if(!$('#shipping_address_checkbox').is(':checked')) {
    $('.shipping-address-fields').removeClass('hidden');
    makeShippingAddressFieldsRequired(true);
    mirrorVisibleBillingAddressFields();
  } else {
    $('.shipping-address-fields').addClass('hidden');
    makeShippingAddressFieldsRequired(false);
  }
}

function toggleShippingAddressFieldsOnClick() {
  toggleShippingAddressFields();
  clearShippingAaddressFields();
}

export function listenForShippingAddressCheckbox() {
  $('#shipping_address_checkbox').on('change', toggleShippingAddressFieldsOnClick);
}

export function listenForShippingCountryChange() {
  $('body').on('change', '#wpf_form_shipping_address_country', function() {
    toggleStateFor($('#wpf_form_shipping_address_country'));
  });
}
