/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

class ApplePayManager {

  constructor(cardBrands) {

    /*
     * @cardBrands:
     * [{'master': {'supportedNetwork': 'masterCard', 'apiVersion': '1'}},
     * {'visa':    {'supportedNetwork': 'visa',       'apiVersion': '1'}}]
     */
    this.cardBrands = cardBrands
  }

  /*
   * Public methods
   */

  get supportedNetworks() {
    return this.attributesForNetwork('supportedNetwork')
  }

  get supportedVersion() {
    const availableVersions = this.attributesForNetwork('apiVersion').sort().reverse()
    const defaultVersion = 1

    return availableVersions
      .find(version => window.ApplePaySession.supportsVersion(version)) ||
        defaultVersion
  }

  static isApplePaySupported() {

    /*
     * We should check also: window.ApplePaySession.canMakePayments()
     * It will do additional checks such, example error:
     * InvalidAccessError: Trying to start an Apple Pay session from an insecure document.
     */
    try {
      return window.ApplePaySession && window.ApplePaySession.canMakePayments()
    } catch(_error) {
      return false
    }
  }

  static async merchantSessionRequest(validationURL) {
    const csrfMetaTag = document.querySelector('meta[name="csrf-token"]')
    const uniqueId = window.location.pathname.split('/').pop()

    try {
      const response = await fetch('/en/mobile/payments/session', {
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfMetaTag.content
        },
        body: JSON.stringify({
          unique_id: uniqueId,
          url:       validationURL
        })
      })

      if (response.ok) {
        return response.json()
      }

      return {}
    } catch(error) {
      return {}
    }
  }

  applePaySessionRequest({
    countryCode,
    currencyCode,
    merchantCapabilities,
    merchantName,
    amount }) {

    /*
     * All params needed!
     * TypeError: Member ApplePayPaymentRequest.(countryCode || currencyCode || etc/all)
     * is required and must be an instance of sequence
     */
    const request = {
      countryCode:          countryCode,
      currencyCode:         currencyCode,
      supportedNetworks:    this.supportedNetworks,
      merchantCapabilities: merchantCapabilities,
      total:                {
        label:  merchantName,
        amount: amount
      }
    }

    return new window.ApplePaySession(this.supportedVersion, request)
  }

  /*
   * Private methods
   */


  attributesForNetwork(attribute) {
    return Object.values(this.cardBrands)
      .map(networkData => Object.values(networkData)[0][attribute])
  }
}

export default ApplePayManager
