/* eslint-disable no-undefined */
/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import GooglePayButton            from '@google-pay/button-react/dist/index.es5.min.js'
import PropTypes                  from 'prop-types'
import React                      from 'react'
import ThreedsV2BrowserAttributes from '@app/features/threeds/v2/collect_browser_attributes'

const API_VERSION = 2
const API_VERSION_MINOR = 0
const AUTH_METHODS = ['PAN_ONLY', 'CRYPTOGRAM_3DS']
const BUTTON_SIZE = 'fill'
const BUTTON_TYPE = 'pay'
const GATEWAY = 'emerchantpay'
const GOOGLE_PAY_TRANSACTION = 'google_pay'
const PAYMENT_METHOD_TYPE = 'CARD'
const PRICE_STATUS = 'FINAL'
const BILLING_ADDRESS_FORMAT = 'FULL'
const SUPPORTED_CARD_BRANDS = ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
const TOKENZIATION_TYPE = 'PAYMENT_GATEWAY'

class GooglePay extends React.Component {

  constructor(props) {
    super(props)

    this.typeCheckbox = React.createRef()

    this.paymentRequest = {
      apiVersion:            API_VERSION,
      apiVersionMinor:       API_VERSION_MINOR,
      allowedPaymentMethods: [
        {
          type:       PAYMENT_METHOD_TYPE,
          parameters: {
            allowedAuthMethods:       AUTH_METHODS,
            allowedCardNetworks:      SUPPORTED_CARD_BRANDS,
            billingAddressParameters: {
              format: BILLING_ADDRESS_FORMAT
            }
          },
          tokenizationSpecification: {
            type:       TOKENZIATION_TYPE,
            parameters: {
              gateway:           GATEWAY,
              gatewayMerchantId: props.gatewayMerchantId
            }
          }
        }
      ],
      merchantInfo: {
        merchantId:     props.merchantId,
        merchantName:   props.merchantName,
        merchantOrigin: props.merchantOrigin
      },
      transactionInfo: {
        totalPriceStatus: PRICE_STATUS,
        totalPrice:       props.amount,
        currencyCode:     props.currency,
        countryCode:      props.country
      }
    }

    this.state = { token: '', openGooglePayModal: false }
  }

  onSubmit() {
    this.setState({ openGooglePayModal: true }, () => {
      document.querySelector('#gpay-button-online-api-id').click()
    })
  }

  onClick(event) {
    if (!this.state.openGooglePayModal) {
      event.preventDefault()

      return this.props.onClick(() => this.onSubmit())
    }

    this.setState({ openGooglePayModal: false })

    return this.paymentRequest
  }

  /*
   * Render component
   */

  render() {
    return (
      <div className='google-pay-transaction-type'>
        <input type='radio'
          name='wpf_form[transaction_type]'
          value={ GOOGLE_PAY_TRANSACTION }
          style={ { display: 'none' } }
          ref={ this.typeCheckbox } />
        { this.state.token &&
          <input type='hidden' name='wpf_form[payment_token]' value={ this.state.token } /> }
        <GooglePayButton
          onClick={event => this.onClick(event)}
          className='google-pay-button'
          environment={ this.props.environment.toUpperCase() }
          buttonLocale={ I18n.locale }
          buttonType={ BUTTON_TYPE }
          buttonSizeMode={ BUTTON_SIZE }
          paymentRequest={ this.paymentRequest }
          onLoadPaymentData={ tokenizationResponse => {
            this.handleWpfFormAfterTokenizationResponse(tokenizationResponse)
          } }
        />
      </div>
    )
  }

  handleWpfFormAfterTokenizationResponse(tokenizationResponse) {
    this.typeCheckbox.current.checked = true
    const token = tokenizationResponse.paymentMethodData.tokenizationData.token

    this.setState({ ...this.state, token: token }, () => {
      ThreedsV2BrowserAttributes.collect()

      document.getElementById('new_wpf_form').submit()
    })
  }
}

GooglePay.propTypes = {
  merchantId:        PropTypes.string.isRequired,
  merchantName:      PropTypes.string.isRequired,
  merchantOrigin:    PropTypes.string.isRequired,
  gatewayMerchantId: PropTypes.string.isRequired,
  amount:            PropTypes.string.isRequired,
  currency:          PropTypes.string.isRequired,
  country:           PropTypes.string,
  environment:       PropTypes.string.isRequired,
  onClick:           PropTypes.func.isRequired
}

GooglePay.defaultProps = {
  country: undefined
}

export default GooglePay
