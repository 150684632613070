export function fakeAntiClickJack() {

  /**
   * Finds the Style tag and Removes it.
   * TODO: Remove this code and the CSS
   */
  var antiClickjack = document.getElementById('antiClickjack');

  antiClickjack.parentNode.removeChild(antiClickjack);
}
