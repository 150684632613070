import * as ValidateType from '@app/features/validate_type_options';
import ThreedsV2BrowserAttributes from '@app/features/threeds/v2/collect_browser_attributes';
import { clearMiddleField as clearCardNumberMiddleField } from '@app/features/populate_card';

function listenForPaymentSubmission() {
  $(document).on('submit', 'form#new_wpf_form', function(event) {
    clearCardNumberMiddleField();
    ThreedsV2BrowserAttributes.collect();
    ValidateType.validateSelectedTypeOptions(event);
  });
}

export default { listenForPaymentSubmission };
