import { exists } from '@app/lib/utility/exists';
import financialServicesBins from '@app/features/financial_services_bins.js';

const BIN_LENGTH = 6;

function isBinInFinancialServicesBins(startingCardNumber) {
  var bin = startingCardNumber.replace(/\s/g, '').substring(0, BIN_LENGTH);

  if (bin.length !== BIN_LENGTH) {
    return false;
  }

  return financialServicesBins.includes(bin);
}

function toggleBirthDateFieldsFor($transactionType) {
  var $birthDate = $transactionType.find('.with-birth-date');
  var $cardNumber = $transactionType.find('input[name*=card_number]');

  if (exists($birthDate) && exists($cardNumber)) {
    var startingCardNumber = $cardNumber.val();

    if (isBinInFinancialServicesBins(startingCardNumber)) {
      $birthDate.removeClass('hidden');
      $birthDate.find('select').prop('required', true);
      $birthDate.find('label abbr').remove();
      $birthDate.find('label').prepend($('<abbr title="required">* </abbr>'));
    } else {
      $birthDate.addClass('hidden');
      $birthDate.find('select').prop('required', false);
      $birthDate.find('label abbr').remove();
    }
  }
}

export function handleBirthDateFields() {
  toggleBirthDateFieldsFor($('.transaction-type-form.credit_card'));
}
