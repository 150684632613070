export function handleBoletoDocumentIdVisibility(gateway, transactionType) {
  if (transactionType !== 'boleto') {
    return;
  }

  const $transactionForm = $('.transaction-type-form.boleto');
  const $wpfFormNationalIdEl = $transactionForm.find('.wpf_form_national_id');
  const $wpfFormDocumentIdEl = $transactionForm.find('.wpf_form_document_id');
  const $formatSelectorEl = $transactionForm.find('#document_id_types');
  const $formatSelectorLabel = $('.transaction-type-form.boleto label:contains(\'CPF/CNPJ\')');

  if (gateway === 'SantanderBrazil') {
    $wpfFormNationalIdEl.remove();
  } else {
    $wpfFormDocumentIdEl.remove();
    $formatSelectorEl.remove();
    $formatSelectorLabel.remove();
  }
}
