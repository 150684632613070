const { cpfFormatter, cnpjFormatter, mexicanDocumentIdFormatter } = require('genesis_wpf_js');

export function handleDocumentIdType(transactionType) {
  const transactionTypesWithDocumentId = ['credit_card', 'online_banking', 'cash'];
  const transactionTypesWithFormattedDocumentId = ['boleto', 'pix'];
  // eslint-disable-next-line id-length
  const mexicanTransactionTypesWithFormattedDocumentId = ['cash', 'online_banking'];
  const brazilianCountryCode = 'BR';
  const mexicanCountryCode = 'MX';

  const $transactionForm = $(`.transaction-type-form.${transactionType}`);
  const documentId = {
    field: $transactionForm.find('#wpf_form_document_id'),
    label: $transactionForm.find('label[for="wpf_form_document_id"]')
  };

  if (transactionTypesWithDocumentId.includes(transactionType) &&
      !$('#document_id_section').data().transaction_type) {
    $('#document_id_section').data({ transaction_type: transactionType });
  }

  if (
    mexicanTransactionTypesWithFormattedDocumentId.includes(transactionType) &&
    $('#wpf_form_billing_address_country').val() === mexicanCountryCode
  ) {
    $('#wpf_form_document_id').on('input', function() {
      const documentIdValue = $(this).val();
      const formattedValue = mexicanDocumentIdFormatter(documentIdValue);

      $(this).val(formattedValue);
    });

    documentId.label
      .html(I18n.t('javascript.wpf_form.mexico_banking_fields.document.label'));
    documentId.field.attr(
      'placeholder',
      I18n.t('javascript.wpf_form.mexico_banking_fields.document.placeholder')
    );

    return;
  }


  if (
    !transactionTypesWithFormattedDocumentId.includes(transactionType) &&
    (!transactionTypesWithDocumentId.includes(transactionType) ||
    $('#wpf_form_billing_address_country').val() !== brazilianCountryCode)
  ) {
    documentId.field.off('input');

    return;
  }

  const $dropdown = $transactionForm.find('#document_id_types');
  const documentIdTypes = {
    CPF: {
      label: I18n.t('javascript.wpf_form.cpf.label'),
      placeholder: I18n.t('javascript.wpf_form.cpf.placeholder'),
      formatter: cpfFormatter
    },
    CNPJ: {
      label: I18n.t('javascript.wpf_form.cnpj.label'),
      placeholder: I18n.t('javascript.wpf_form.cnpj.placeholder'),
      formatter: cnpjFormatter
    }
  };

  $dropdown.val(I18n.t('javascript.wpf_form.cpf.label')).trigger('change');
  documentId.field.attr('placeholder', I18n.t('javascript.wpf_form.cpf.placeholder'));
  documentId.label.html(I18n.t('javascript.wpf_form.cpf.label'));
  documentId.field.val('');

  $dropdown.on('change', function() {
    documentId.field.val('');
    const documentIdType = documentIdTypes[$(this).val()];

    documentId.field.attr('placeholder', documentIdType.placeholder);
    documentId.label.html(documentIdType.label);
  });

  documentId.field.on('input', function() {
    const formattedValue = documentId.field.val();
    const documentIdValue = documentIdTypes[$dropdown.val()].formatter(formattedValue);

    documentId.field.val(documentIdValue);
  });
}
