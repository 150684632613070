import { makeFieldNotRequired, makeFieldRequired } from '@app/features/required_fields_helpers';
import { animateTransactionTypeContainer } from '@app/features/payment_type_select';

let ISSUING_BINS = [];
const CARD_BIN_LENGTH = 6;

function handleCardId(cardNumber) {
  if ($('.card-dependable-fields.with-tokenized-card').is(':visible')) {
    return;
  }

  var cardIdSelector = 'issued-card-id';
  var bin = cardNumber.replace(/\s/g, '').substring(0, CARD_BIN_LENGTH);

  if(ISSUING_BINS.includes(bin)) {
    makeFieldRequired(cardIdSelector);
  } else {
    makeFieldNotRequired(cardIdSelector);
  }

  animateTransactionTypeContainer();
}

function cacheIssuingBinsOnce() {
  if (!ISSUING_BINS.length && $('.issued-card-id').length) {
    $.ajax({
      method: 'GET',
      url: '/wpf/issuing_provider_specifics'
    }).done(function(data) {
      ISSUING_BINS = data['issuing_bins'];
      handleCardId($('#wpf_form_card_number').val());
    });
  }
}

export default { handleCardId, cacheIssuingBinsOnce };
