import { animateTransactionTypeContainer } from '@app/features/payment_type_select';

function clearHiddenStateFields() {

  function appendEmptyStateFieldFor(address_type) {
    $('<input type="hidden" name="wpf_form[' + address_type + '_state]" />')
      .appendTo('.' + address_type.replace('_address', '') + '-address-states');
  }

  function clearHiddenStateFor(address_type) {
    var state_fields = 'select#wpf_form_' + address_type + '_state';
    var country_code = $('#wpf_form_' + address_type + '_country').val();

    if (country_code) {
      $(state_fields).not('.' + country_code).remove();
    } else {
      $(state_fields).remove();
    }

    if ($(state_fields).length === 0) {
      appendEmptyStateFieldFor(address_type);
    }
  }

  function clearTokenifEmpty() {
    if ($('.tokens-container input:checked').val() === '') {
      $('.tokens-container input:checked').remove();
      $('.tokenized-card-form input').remove();
    }
  }

  clearHiddenStateFor('billing_address');
  clearHiddenStateFor('shipping_address');
  clearTokenifEmpty();
}

function showCustomErrorContainer(element) {
  if(element.hasClass('hidden')) {
    element.removeClass('hidden');
  }

  animateTransactionTypeContainer();
}

function switchToLoadingSubmitButton() {
  var $payButton = $('#new_wpf_form').find('input[type=submit]');

  $payButton.prop('disabled', true).addClass('loading');
}

function disableCancelLink() {
  $('.payment-form-footer .cancel').on('click', function(event) {
    event.preventDefault();
  });
}

export function validateSelectedTypeOptions(event) {
  var errorElement = $('.error.transaction-types');
  var $transactionTypeRadio = $('.transaction-types input[type="radio"]');
  var $methodCategoryRadio = $('.payment-method-categories input[type="radio"]');
  var $paymentTypeRadio = $('.payment-types input[type="radio"]');
  var transactionType = $transactionTypeRadio.filter(':checked').val();
  var paymentTypes = $('.transaction-type-form.' + transactionType + '.with-payment-types');
  var withPaymentTypes = paymentTypes.length;
  var typeSelected = true;

  if ($methodCategoryRadio.length !== 0 && !$methodCategoryRadio.is(':checked')) {
    var methodCategoryTrxType = $methodCategoryRadio.parents('.transaction-type-form')
      .attr('class')
      .replace('transaction-type-form ', '');

    if (transactionType === methodCategoryTrxType) {
      var methodCategoriesErrorElement = $('.error.payment-method-categories');

      showCustomErrorContainer(methodCategoriesErrorElement);
      event.stopImmediatePropagation();
      event.preventDefault();

      return false;
    }
  }

  if ($transactionTypeRadio.is(':checked') &&
    withPaymentTypes && !$paymentTypeRadio.is(':checked')) {

    errorElement = $('.error.payment-types');
    typeSelected = false;
  } else if (!$transactionTypeRadio.is(':checked')) {
    errorElement = $('.error.transaction-types');
    typeSelected = false;
  }

  if (!typeSelected) {
    showCustomErrorContainer(errorElement);
    event.stopImmediatePropagation();
    event.preventDefault();

    return false;
  }

  switchToLoadingSubmitButton();
  clearHiddenStateFields();
  disableCancelLink();
}
