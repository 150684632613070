/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import PropTypes         from 'prop-types'
import React             from 'react'
import RedirectButton    from '@app/components/presentational/redirect-button'

class ConfirmationPage extends React.Component {

  /*
   * Render component
   */

  render() {
    return (
      <div className='confirmation'>
        <h1>{I18n.t(`javascript.confirmation.messages.${this.props.status}`)}</h1>
        <RedirectButton
          buttonClass='redirect-button'
          delaySeconds={this.props.delaySeconds}
          redirectUrl={this.props.redirectUrl}
          withDelay={this.props.withDelay}
        />
      </div>
    )
  }
}

ConfirmationPage.propTypes = {
  delaySeconds: PropTypes.number,
  status:       PropTypes.string.isRequired,
  redirectUrl:  PropTypes.string,
  withDelay:    PropTypes.bool
}

export default ConfirmationPage
