/*
* The Payment variable is included from jquery.card aka. window.Payment
* Does not looks very wise to use plugins internal methods!!!!
*/

import EzeecardPayout from '@app/features/ezeecard_payout_custom_attributes';
import { animateTransactionTypeContainer } from '@app/features/payment_type_select';
import { handleBirthDateFields } from '@app/features/handle_birth_date';
import { isIeLessThan9 } from '@app/lib/utility/ie';
import { loadInteractiveCard } from '@app/features/interactive_card';
import { simulateKeyEvent } from '@app/lib/utility/simulate_keys';

require('jquery.card');

const MAX_LENGTH = 15;

function handleCardNumberErrorMessage(form) {
  var selector = '.wpf_form_card_number';
  var cardNumberField = form.find(selector + '.field_with_errors #wpf_form_card_number, #middle');
  var errorElement = form.find(selector + ' span.error');

  cardNumberField.on('focus', function() {
    errorElement.hide();
  });
}


export function listenForCardInputChange() {
  $('input[name="wpf_form[card_number]"]').on('keyup', function() {
    handleBirthDateFields();

    EzeecardPayout.handleCardId($('#wpf_form_card_number').val());
    animateTransactionTypeContainer();
  });
}

function combineCardNumber(form) {
  var cardNumberField   = form.find('#wpf_form_card_number');
  var bin               = form.find('#bin').val().trim();
  var tail              = form.find('#tail').val().trim();
  var middle            = form.find('#middle').val();
  var newCardNumber     = bin + middle + tail;

  if(!isIeLessThan9()) {
    cardNumberField.val(window.Payment.fns.formatCardNumber(newCardNumber));
    simulateKeyEvent(cardNumberField.get(0), 'keyup');
    simulateKeyEvent(cardNumberField.get(0), 'keypress');
  } else {
    cardNumberField.val(newCardNumber);
  }
}

function setMiddleCardNumberMaxLen(form) {
  var middleField = form.find('#middle');

  if(!isIeLessThan9()) {
    window.Payment.restrictNumeric(middleField.get(0));

    let maxLen = MAX_LENGTH;

    var cardsArray    = window.Payment.getCardArray();
    var cardType      = window.Payment.fns.cardType(form.find('#wpf_form_card_number').val());
    var binLen        = form.find('#bin').val().length;
    var tailLen       = form.find('#tail').val().length;

    var card = $.grep(cardsArray, function(cardItem) {
      return cardItem.type === cardType;
    });

    if(card && card.length > 0) {
      maxLen = card[0].length[card[0].length.length - 1];
    }

    maxLen = maxLen - binLen - tailLen;

    middleField.attr('maxlength', maxLen);
  }
}

function listenForCardNumberChange(form) {
  var splitFields       = form.find('#middle, #bin, #tail');
  var jpCardNumber      = form.find('jp-card-number');
  var splitCardNumber   = form.find('.split-card-number');

  splitFields.on('keyup', function() {
    combineCardNumber(form);
    setMiddleCardNumberMaxLen(form);
  });

  form.find('#middle').on('focus', function() {
    jpCardNumber.addClass('jp-card-focused');
    splitCardNumber.addClass('focus');
  });

  form.find('#middle').on('blur', function() {
    jpCardNumber.removeClass('jp-card-focused');
    splitCardNumber.removeClass('focus');
  });
}

export function populateCardNumberFields(bin, tail) {
  var binValue = bin;
  var tailValue = tail;

  if(typeof bin === 'undefined' || bin === null) {
    binValue = '';
  }

  if(typeof tail === 'undefined' || tail === null) {
    tailValue = '';
  }

  var transaction_type_selectors = '.transaction-type-form.credit_card:not(.hidden), ' +
                                   '.transaction-type-form.ezeewallet_payout:not(.hidden), ' +
                                   '.transaction-type-form.account_verification:not(.hidden)';

  $(transaction_type_selectors).each(function() {
    var form              = $(this);
    var cardNumberField   = form.find('#wpf_form_card_number');
    var binField          = form.find('#bin');
    var tailField         = form.find('#tail');
    var middleField       = form.find('#middle');
    var cardNumber        = cardNumberField.val();
    var middleNumber      = cardNumber;
    var splitCardNumber   = form.find('.split-card-number');

    if(binValue !== '' || tailValue !== '') {
      cardNumberField.addClass('hidden-field').attr('tabindex', '-1');
      middleField.attr('tabindex', '2');
      splitCardNumber.removeClass('hidden');
    }

    if(binValue === '') {
      binField.hide();
      middleField.addClass('with-bin');
    } else {
      middleNumber = middleNumber.substr(binValue.length);
      binField.val(binValue);
    }

    if(tailValue === '') {
      tailField.hide();
      middleField.addClass('with-tail');
    } else {
      middleNumber = middleNumber.substr(0, middleNumber.length - tailValue.length);
      tailField.val(tailValue);
    }

    middleField.val(middleNumber);

    listenForCardNumberChange(form);
    setMiddleCardNumberMaxLen(form);
    combineCardNumber(form);

    if(!isIeLessThan9()) {
      loadInteractiveCard();
    }

    handleCardNumberErrorMessage(form);
  });
}

export function populateExpirationDateFields(expirationMonth, expirationYear) {

  if(expirationMonth === null || expirationYear === null) {
    return;
  }

  var trx_type_selector = '.transaction-type-form.credit_card, ' +
                          '.transaction-type-form.ezeewallet_payout, ' +
                          '.transaction-type-form.account_verification';

  $(trx_type_selector)
    .each(function() {
      var form                    = $(this);
      var monthSelect             = form.find('#wpf_form_expiration_date_2i');
      var yearSelect              = form.find('#wpf_form_expiration_date_1i');
      var expirationMonthField    = form.find('input#expiration_month');
      var expirationYearField     = form.find('input#expiration_year');
      var fixedExpirationDate     = form.find('.fixed-expiration-date');

      if(typeof expirationMonth !== 'undefined') {
        monthSelect.addClass('hidden-field');
        monthSelect.val(expirationMonth);

        var selectedMonth = monthSelect.find('option:selected').text();

        expirationMonthField.val(selectedMonth);
      }

      if(typeof expirationYear !== 'undefined') {
        yearSelect.addClass('hidden-field');
        yearSelect.val(expirationYear);
        expirationYearField.val(expirationYear);
      }

      if(typeof expirationYear !== 'undefined' && typeof expirationMonth !== 'undefined') {
        fixedExpirationDate.removeClass('hidden');
        monthSelect.attr('tabindex', '-1');
        yearSelect.attr('tabindex', '-1');
      }
    });
}

export function clearMiddleField() {

  /**
   * For credit card transactions - clear 'middle' so it does not
   * show in plain text in the logs after the form is submitted
   */
  $('input[name="middle"]').val('');
}
