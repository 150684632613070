export function simulateKeyEvent(elem, type) {
  if(/^key/.test(type)) {
    var event = null;

    if(document.createEvent) {
      try {
        event = document.createEvent('KeyEvents');
        event.initKeyEvent(type);
      } catch(err) {
        event = document.createEvent('Events');
        event.initEvent(type, true, true);
      }
    } else if(document.createEventObject) {
      event = document.createEventObject();
    }

    if(elem.dispatchEvent) {
      elem.dispatchEvent(event);
    } else if(elem.fireEvent) {
      elem.fireEvent('on' + type, event);
    }
  }
}
