/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Button                         from '@app/components/presentational/base/button'
import PropTypes                      from 'prop-types'
import React                          from 'react'
import SpeiInstruction                from './spei-instruction'
import { ReactComponent as SpeiLogo } from '../../../images/transaction_types/se.svg'
import SpeiRow                        from './spei-row'

class SpeiPage extends React.Component {

  /*
   * Render component
   */

  render() {
    const instructions = [
      {
        label: 1,
        text:  `Usa el botón Copiar para asegurar de que esta utilizando
                informacion del beneficiaro correcta`
      },
      {
        label: 2,
        text:  `Vaya a su Online banking, selecciona SPEI como su methodo de pago
               y copia los detalles del pago respectivo`
      },
      {
        label: 3,
        text:  <>No olvides indicar el <b>Concepto</b>
               &nbsp;en la transferencia - si no, el pago sera rechazado</>
      }
    ]

    const rowElements = [
      {
        label:     'Nomber del Banco',
        value:     'STP (Sistema de Transferencias y Pagos)',
        valueIcon: false
      },
      {
        label:     'CLABE',
        value:     this.props.empAccountNumber,
        valueIcon: true
      },
      {
        label:     'Concepto',
        value:     this.props.uniqueId,
        valueIcon: true
      },
      {
        label:     'Referncia numerica',
        value:     this.props.referenceNumber,
        valueIcon: true
      },
      {
        label:     'Fecha limite de pago',
        value:     this.props.validUntil,
        valueIcon: false
      }
    ]

    return (
      <div className='spei-page'>
        <h2>Su pago ha sido creado</h2>
        <div className='spei-summary'>
          <div className='item'>
            <SpeiLogo style={{ width: '70px', height: '50px' }} />
            <div className='payment-amount-title'>
              Datos de la compra
            </div>
          </div>
          {rowElements.map(rowObject => <SpeiRow key={rowObject.label} {...rowObject} />)}
          <div className='item'>
            <div className='payment-amount-title'>
              Total
            </div>
            <div className='payment-amount-value'>
              {this.props.amount} {this.props.currency}
            </div>
          </div>
        </div>
        <h2>Instrucciones de pago</h2>
        <div className='instruction-container'>
          { instructions.map(boxObject => <SpeiInstruction
            key={boxObject.label} {...boxObject}
          />) }
        </div>
        <div className='spei-container'>
          { this.props.redirectUrl &&
            <Button
              className={'redirect-button'}
              onClick={() => this.redirect()}>
              Volver atrs
            </Button>
          }
        </div>
      </div>
    )
  }

  /*
   * Private methods
   */

  redirect() {
    window.location.replace(this.props.redirectUrl)
  }
}

SpeiPage.propTypes = {
  empAccountNumber: PropTypes.string,
  uniqueId:         PropTypes.string,
  referenceNumber:  PropTypes.string,
  redirectUrl:      PropTypes.string,
  validUntil:       PropTypes.string,
  amount:           PropTypes.string,
  currency:         PropTypes.string
}

export default SpeiPage
