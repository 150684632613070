import { changeInputStateFor } from '@app/lib/utility/change_input_state';

const Modernizr = { svg: true };

export function loadLogosImages() {
  $('.svg-image').each(function() {
    var fallback = $(this).attr('data-fallback');
    var svgImage = $(this).attr('data-svg');

    if (Modernizr.svg && typeof svgImage !== 'undefined' && svgImage !== 'null') {
      $(this).append('<img src="' + svgImage + '" />');
    } else {
      $(this).append('<img src="' + fallback + '" />');
    }
  });
}

export function disableAllTransactionTypeForms() {
  changeInputStateFor($('#transaction-types-form-container'), true);
}
