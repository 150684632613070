import { exists } from '@app/lib/utility/exists';

function browserInputFor(attribute) {
  return `div.threeds-v2-browser-params input[name="${attribute}"]`;
}

function collect3dsv2BrowserAttributes() {
  var javaEnabled    = (navigator.javaEnabled && navigator.javaEnabled()) || false;
  var language       = navigator.language;
  var colorDepth     = screen.colorDepth;
  var screenHeight   = screen.height;
  var screenWidth    = screen.width;
  var timezoneOffset = new Date().getTimezoneOffset();

  $(browserInputFor('browser_java_enabled')).val(javaEnabled);
  $(browserInputFor('browser_language')).val(language);
  $(browserInputFor('browser_color_depth')).val(colorDepth);
  $(browserInputFor('browser_screen_height')).val(screenHeight);
  $(browserInputFor('browser_screen_width')).val(screenWidth);
  $(browserInputFor('browser_time_zone_offset')).val(timezoneOffset);
}

function collect() {
  if (exists($('.threeds-v2-browser-params'))) {
    collect3dsv2BrowserAttributes();
  }
}

export default { collect };

