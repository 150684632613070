/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import PropTypes from 'prop-types'
import React     from 'react'

const SpeiInstruction = props => <div className='instruction-box'>
  <div className='instruction-badge'>{props.label}</div>
  {props.text}
</div>

SpeiInstruction.propTypes = {
  label: PropTypes.number,
  text:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}
export default SpeiInstruction
