/* eslint semi: ["error", "never"]*/
/* eslint arrow-parens: ["error", "as-needed"]*/
/* eslint key-spacing: ["error", { "afterColon": true, "align": "value" }] */
/* eslint no-multi-spaces: ["error", { exceptions: { "ImportDeclaration": true,
                                       "VariableDeclarator": true  } }] */

import Button from '@app/components/presentational/base/button'
import React from 'react'

export const modal = {
  children: null,
  onSubmit: null,
  onCancel: null,
  toggle:   null
}

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.modalElement = null

    this.initialState = {
      isOpen:      false,
      cancelLabel: I18n.t('javascript.actions.cancel'),
      submitLabel: I18n.t('javascript.actions.submit'),
      className:   ''
    }

    this.state = { ...this.initialState }
  }

  componentDidMount() {
    this.modalElement = document.querySelector('#wpf-modal')
    modal.toggle = this.toggle.bind(this)
  }

  toggle({ children = null, onSubmit = null, onCancel = null, ...rest }) {
    modal.children = children
    modal.onSubmit = onSubmit
    modal.onCancel = onCancel

    this.setState({ ...rest })
    this.modalElement.classList.toggle('show')
  }

  onSubmit() {
    modal.onSubmit && modal.onSubmit()
    this.toggle({ ...this.initialState })
  }

  onCancel() {
    modal.onCancel && modal.onCancel()
    this.toggle({ ...this.initialState })
  }

  render() {
    return (
      <div className='modal-wrapper'>
        <div className={`modal-content ${this.state.className}`}>
          { this.state.isOpen && modal.children }
        </div>
        <div className='modal-footer'>
          <Button onClick={() => this.onCancel()}>{this.state.cancelLabel}</Button>
          <Button onClick={() => this.onSubmit()}>{this.state.submitLabel}</Button>
        </div>
      </div>
    )
  }
}

export default Modal
